<script>
import ExportButton from "@/views/pages/export/ExportButton.vue";
import {BCard, BCardTitle, BCol, BRow} from "bootstrap-vue";
import VLTTemplateList from "@/views/pages/templates/vlt-templates/vlt-template-defualt/VLTTemplateList.vue";
import store from "@/store";
import vltTemplatesModule from "@/views/pages/templates/vlt-templates/store/vltStoreModule";
import {mapState} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import GoldCharger from "@/views/components/vlt-templates/Gold/GoldCharger.vue";

export default {
  name: "index.vue",
  components: {
    VLTTemplateList,
    ExportButton,
    GoldCharger,
    BCardTitle,
    BCard,
    BCol,
    BRow,
  },
  data() {
    return {
      showOptions: false,
      MODULE_APP_STORE_NAME: "vlt-templates",
      errorFetchingTemplates: false,
      errorSettingTemplates: false,
      vltTemplates: [],
      loading: false,
    };
  },
  computed: {
    ...mapState("vlt-templates", [
      "loadingFetch",
      "loadingFetchDefaults",
    ]),

    getCurrentTemplate() {
      return (
          this.vltTemplates.find((template) => template.currentTemplate) ||
          null
      );
    },

    getVLTSelect() {
      return store.state.whitelabelCurrencyNabvar.whitelabel
    }
  },

  watch: {
    getVLTSelect: {
      handler(newVLT, oldVLT) {
        if (newVLT?._id !== oldVLT?._id)
          this.geTemplates()
      },
    },
  },


  methods: {
    async deleteTemplate(id) {
      try {
        this.loading = true;
        const queryParams = {
          whitelabelId: this.$store.state.whitelabelCurrencyNabvar.whitelabel._id,
          templateId: id,
        };
        await this.$store.dispatch(
            "vlt-templates/removeTemplate",
            queryParams
        );
        this.vltTemplates = this.vltTemplates.filter(
            (template) => template._id !== id
        );
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('template_deleted'),
            icon: "CheckIcon",
            variant: "success",
          },
        });
      } catch (e) {
        //error
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    editTemplate(type, id) {
      this.$router.push({name: type + "-vlt-template-preview", query: {id, layout: 'horizontal'}});
    },

    async geTemplates() {
      try {
        this.loading = true;
        this.errorFetchingTemplates = false;
        const queryParams = {
          whitelabelId: this.$store.state.whitelabelCurrencyNabvar.whitelabel._id,
        };
        this.vltTemplates = await this.$store.dispatch(
            "vlt-templates/fetchTemplates",
            queryParams
        );
        this.$store.commit(`vlt-templates/SET_EDIT_TEMPLATE`, this.getCurrentTemplate);

      } catch (e) {
        //error
        this.errorFetchingTemplates = true;
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    async setToDefaultTemplate(id) {
      try {
        this.loading = true;
        const params = {
          whitelabelId: this.$store.state.whitelabelCurrencyNabvar.whitelabel._id,
          templateId: id,
        };
        await this.$store.dispatch("vlt-templates/setTemplate", {
          ...params,
        });
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('template_default'),
            icon: "CheckIcon",
            variant: "success",
          },
        });
        await this.geTemplates();
      } catch (e) {
        //error
        this.errorSettingTemplates = true;
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },

  async mounted() {
    // Register module
    await this.geTemplates();
  },

  created() {
    if (!store.hasModule(this.MODULE_APP_STORE_NAME))
      store.registerModule(
          this.MODULE_APP_STORE_NAME,
          vltTemplatesModule
      );
    store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", true);
  },

  beforeDestroy() {
    if (store.hasModule(this.MODULE_APP_STORE_NAME))
      store.unregisterModule(this.MODULE_APP_STORE_NAME);
  },
};
</script>

<template>
  <div v-if="loadingFetch">{{ $t('loading_created_templates') }}</div>

  <div v-else-if="!loadingFetch && errorFetchingTemplates">Error</div>

  <div v-else>
    <b-card>
      <b-card-header>
        <b-row class="w-100 align-items-center">
          <b-col md="10">
            <b-card-title class="mb-0">{{ $t('current_template') }}</b-card-title>
          </b-col>

          <b-col md="2" class="d-flex justify-content-end">
            <b-button
                to="/apps/vlt-template/new-template"
                variant="primary"
                :disabled="loading"
            >
              {{ $t('new_vlt_template') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>

      <b-card-body class="pt-1">
        <div class="row" v-if="getCurrentTemplate">
          <div class="w-100 mx-2 template-card">
            <div class="card">
              <component
                  :is="getCurrentTemplate.type + '-charger'"
                  size="monitor"
                  class="image"
                  :template-data="getCurrentTemplate.templateData"
              >
              </component>
              <div class="middle">
                <div class="btn-box shadow-sm">
                  <div class="row">
                    <div class="col">
                      <b-button
                          :disabled="loading"
                          @click="
                          editTemplate(
                            getCurrentTemplate.type,
                            getCurrentTemplate._id
                          )
                        "
                          variant="primary"
                      >
                        {{ $t('buttons.edit') }}
                      </b-button>
                    </div>
                    <div class="col" v-show="false">
                      <b-button
                          disabled
                          variant="primary-outline"
                          @click="deleteTemplate(getCurrentTemplate.id)"
                      >
                        Delete
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <strong class="d-inline"
            >{{ getCurrentTemplate.name }} {{ $t('template') }}</strong
            >
            <span> ({{ getCurrentTemplate.type }})</span>
          </div>
        </div>
        <div v-else>{{ $t('vlt_without_templates') }} {{ $t('default') }}</div>
      </b-card-body>
    </b-card>

    <b-card>
      <b-card-header>
        <b-row class="w-100">
          <b-col md="10">
            <b-card-title class="mb-0">{{ $t('created_templates') }}</b-card-title>
          </b-col>
        </b-row>
      </b-card-header>

      <b-card-body class="pt-1">
        <VLTTemplateList
            :loading="loading"
            @delete-template="(id) => deleteTemplate(id)"
            @set-template="(id) => setToDefaultTemplate(id)"
            @edit-template="({ id, type }) => editTemplate(type, id)"
            :vlt-templates="vltTemplates"
        />
      </b-card-body>
    </b-card>
  </div>
</template>

<style scoped lang="scss">
.template-card {
  .card {
    border-radius: 8px;
    padding: 2px;
    border: 2px solid #efb810;
    cursor: pointer;
    overflow: hidden;

    .image {
      opacity: 1;
      height: 300px;
      transition: 0.5s ease;
      backface-visibility: hidden;
      padding: 8px;
    }

    .middle {
      transition: 0.5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -20%);
      -ms-transform: translate(-50%, -20%);
      text-align: center;

      .btn-box {
        background: #fff;
        padding: 15px;
        border-radius: 8px;
      }
    }
  }

  &.selected .card {
    border: 2px solid #efb810;
    padding: 5px;
    transition: 0.1s ease-in;
    z-index: 1;
  }

  &:hover {
    .image {
      opacity: 0.3;
    }

    .middle {
      opacity: 1;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
  }
}
</style>
