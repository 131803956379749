<script>
import { BCardTitle } from "bootstrap-vue";

export default {
  name: "VTLTemplateList",
  components: {  BCardTitle },
  props: ["vltTemplates", "loading"],
  computed: {
    vltTemplatesWhitOutCurrent() {
      return (
        this.vltTemplates.filter(
          (templates) => !templates.currentTemplate
        ) || []
      );
    },
  },
  data() {
    return {
      visibleCount: 0,
    };
  },
  mounted() {
    this.revealTemplates();
  },
  methods: {
    getInitials(name) {
      return name
        .split(" ")
        .map((word) => word[0])
        .join("")
        .toUpperCase();
    },
    revealTemplates() {
      if (this.visibleCount < this.vltTemplatesWhitOutCurrent.length) {
        setTimeout(() => {
          this.visibleCount++;
          this.revealTemplates();
        }, 7000);
      }
    },
  },
};
</script>

<template>
  <div  v-if="vltTemplatesWhitOutCurrent.length">
    <div class="row" style="gap: 40px">
      <b-list-group class="w-100">
        <b-list-group-item
            class="d-flex align-items-center"
            v-for="(template, index) in vltTemplatesWhitOutCurrent"
            :key="index"
        >
          <b-avatar square size="lg" :text="getInitials(template.name + ' ' + template.type)" class="mr-3"></b-avatar>
          <div class="mr-auto">
            <strong>{{ template.name }} {{ $t("template") }}</strong>
            <span> ({{ template.type }})</span>
          </div>

          <div class="d-flex" style="gap: 10px">
            <b-button
                variant="success"
                size="sm"
                :disabled="loading"
                @click="$emit('set-template', template._id)"
            >Set template</b-button
            >
            <b-button
                variant="primary"
                size="sm"
                :disabled="loading"
                @click="
                $emit('edit-template', {
                  type: template.type,
                  id: template._id,
                })
              "
            >Edit</b-button
            >
            <b-button
                variant="danger"
                size="sm"
                :disabled="loading"
                @click="$emit('delete-template', template._id)"
            >Delete</b-button
            >
          </div>
        </b-list-group-item>
      </b-list-group>

      <!--
      <div
        v-for="(template, index) in whitelabelTemplatesWhitOutCurrent.slice(
          0,
          visibleCount
        )"
        class="template-card"
        :key="index"
      >
        <div class="card">
          <component
            :is="`${template.type}-charger`"
            class="image"
            custom-width="100%"
            custom-height="800%"
            :show-scroll="false"
            style="
              height: 310px;
              width: 2040px;
              transform: scale(0.15);
              transform-origin: 0 0;
            "
            :template-data="template.templateData"
          >
          </component>

          <div class="middle">
            <div class="btn-box shadow-sm">
              <div class="row my-2">
                <div class="col">
                  <b-button
                    :disabled="loading"
                    @click="
                      $emit('edit-template', {
                        type: template.type,
                        id: template._id,
                      })
                    "
                    variant="primary"
                  >
                    Edit
                  </b-button>
                </div>
                <div class="col">
                  <b-button
                    :disabled="loading"
                    @click="$emit('delete-template', template._id)"
                    variant="primary-outline"
                  >
                    Delete
                  </b-button>
                </div>
                <div class="col">
                  <b-button
                    :disabled="loading"
                    @click="$emit('set-template', template._id)"
                    variant="primary-outline"
                  >
                    Set template
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <strong class="d-inline">{{ template.name }} {{ $t('template') }}</strong>
        <span> ({{ template.type }})</span>
      </div>

      <div
        v-if="visibleCount < whitelabelTemplatesWhitOutCurrent.length"
        class="p-2"
      >
        {{ $t('preparing_template') }}...
      </div>
      -->
    </div>
  </div>
  <div v-else>{{ $t("vlt_without_templates") }}</div>
</template>

<style scoped lang="scss">
.template-card {
  .card {
    border-radius: 8px;
    overflow: hidden;
    width: 300px;
    height: 300px;

    .image {
      //height: 310px;
      //width: 2040px;
      //transform: scale(0.15);
      //transform-origin: 0 0;
      opacity: 1;
      transition: 0.5s ease;
      backface-visibility: hidden;
      padding: 8px;
    }

    .middle {
      transition: 0.5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -30%);
      -ms-transform: translate(-50%, -30%);
      text-align: center;

      .btn-box {
        background: #fff;
        padding: 5px;
        border-radius: 8px;
      }
    }
  }

  &.selected .card {
    border: 2px solid #efb810;
    padding: 5px;
    transition: 0.1s ease-in;
    z-index: 1;
  }

  &:hover {
    .image {
      opacity: 0.3;
    }
    .middle {
      opacity: 1;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
  }
}
</style>
