var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loadingFetch)?_c('div',[_vm._v(_vm._s(_vm.$t('loading_created_templates')))]):(!_vm.loadingFetch && _vm.errorFetchingTemplates)?_c('div',[_vm._v("Error")]):_c('div',[_c('b-card',[_c('b-card-header',[_c('b-row',{staticClass:"w-100 align-items-center"},[_c('b-col',{attrs:{"md":"10"}},[_c('b-card-title',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('current_template')))])],1),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"md":"2"}},[_c('b-button',{attrs:{"to":"/apps/vlt-template/new-template","variant":"primary","disabled":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('new_vlt_template'))+" ")])],1)],1)],1),_c('b-card-body',{staticClass:"pt-1"},[(_vm.getCurrentTemplate)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"w-100 mx-2 template-card"},[_c('div',{staticClass:"card"},[_c(_vm.getCurrentTemplate.type + '-charger',{tag:"component",staticClass:"image",attrs:{"size":"monitor","template-data":_vm.getCurrentTemplate.templateData}}),_c('div',{staticClass:"middle"},[_c('div',{staticClass:"btn-box shadow-sm"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-button',{attrs:{"disabled":_vm.loading,"variant":"primary"},on:{"click":function($event){return _vm.editTemplate(
                          _vm.getCurrentTemplate.type,
                          _vm.getCurrentTemplate._id
                        )}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.edit'))+" ")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"col"},[_c('b-button',{attrs:{"disabled":"","variant":"primary-outline"},on:{"click":function($event){return _vm.deleteTemplate(_vm.getCurrentTemplate.id)}}},[_vm._v(" Delete ")])],1)])])])],1),_c('strong',{staticClass:"d-inline"},[_vm._v(_vm._s(_vm.getCurrentTemplate.name)+" "+_vm._s(_vm.$t('template')))]),_c('span',[_vm._v(" ("+_vm._s(_vm.getCurrentTemplate.type)+")")])])]):_c('div',[_vm._v(_vm._s(_vm.$t('vlt_without_templates'))+" "+_vm._s(_vm.$t('default')))])])],1),_c('b-card',[_c('b-card-header',[_c('b-row',{staticClass:"w-100"},[_c('b-col',{attrs:{"md":"10"}},[_c('b-card-title',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('created_templates')))])],1)],1)],1),_c('b-card-body',{staticClass:"pt-1"},[_c('VLTTemplateList',{attrs:{"loading":_vm.loading,"vlt-templates":_vm.vltTemplates},on:{"delete-template":function (id) { return _vm.deleteTemplate(id); },"set-template":function (id) { return _vm.setToDefaultTemplate(id); },"edit-template":function (ref) {
                                                var id = ref.id;
                                                var type = ref.type;

                                                return _vm.editTemplate(type, id);
}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }